import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';

const Shippingdetails = () =>{
    const [userName, setUserName] = useState("");
    const [userAddress, setUserAddress] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [user, setUser] = useState([]);
    const [detail, setDetail] = useState([]);
    const reddirect = useNavigate();
    const [token, setToken] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        // Creating a timeout within the useEffect hook
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    }, []);


    function addUserName(e) {
        setUserName(e.target.value);
      }
      function addUserAddress(e) {
        setUserAddress(e.target.value);
      }
      function addUserPhone(e) {
        setUserPhone(e.target.value);
      }

    useEffect(() => {
        // Check if token exists in localStorage (or wherever you store it)
        const storedToken = localStorage.getItem('accessToken');
        if (storedToken) {
          setToken(storedToken);
        }
      }, []);
    // Fetching User Detail
    useEffect(() => {
        const storedToken = localStorage.getItem('accessToken');
        const fetchData = async () => {
            const url = 'https://bhenapi.wd99p.com/api/v1/userdetail';
            try {
                const response = await fetch(url, {
                    headers: {
                        'Authorization': `Bearer ${storedToken}`,
                    
                    }
                });
            
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            
                const jsonData = await response.json();
                setDetail(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

     // Fetching User Data
    useEffect(() => {
      const storedToken = localStorage.getItem('accessToken');
      const fetchData = async () => {
          const url = 'https://bhenapi.wd99p.com/api/v1/user';
        
          try {
              const response = await fetch(url, {
                  headers: {
                      'Authorization': `Bearer ${storedToken}`,
                    
                  }
              });
            
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
            
              const jsonData = await response.json();
              setUser(jsonData.data);
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      };
      fetchData();
    },[]);

    function addUserdetails(e) {
        const storedToken = localStorage.getItem('accessToken');
        e.preventDefault();
        async function saveDetails() {
            const response = await fetch(
              "https://bhenapi.wd99p.com/api/v1/userdetail",
              {
                method: 'POST',
                headers: {
                  "Content-Type" : "application/json",
                  "accept" : "application/json",
                  'Authorization': `Bearer ${storedToken}`
                },
                   body: JSON.stringify({
                    full_name: userName,
                    address: userAddress,
                    contact_no: userPhone
                   })
              }
      
              
            );
            if (response.status !== 200) {
            console.log("error");
            }
            else{
              document.location.reload();
            }
          }
          saveDetails();
    }

    function updateUserdetails(e) {
        const storedToken = localStorage.getItem('accessToken');
        e.preventDefault();
        async function saveDetails() {
            const response = await fetch(
                "https://bhenapi.wd99p.com/api/v1/userdetail/"+detail[0].id,
                {
                  method: 'PATCH',
                  headers: {
                    "Content-Type" : "application/json",
                    "accept" : "application/json",
                    'Authorization': `Bearer ${storedToken}`
                  },
                   body: JSON.stringify({
                    full_name: userName,
                    address: userAddress,
                    contact_no: userPhone
                   })
              }
      
              
            );
            if (response.status !== 200) {
            console.log("error");
            }
            else{
              document.location.reload();
            }
          }
          saveDetails();
    }

      if(token != null){
        return(
            <section>
            <div class="container-sm d-flex flex-column flex-lg-row justify-content-center align-self-stretc p-5">
            <div class="col-lg-4 p-3">
                    <h4>Shipping Details</h4>
                    <form>
                          <div class="mb-3">
                            <label>Full Name</label>
                            <input  type="email" name="email" id="email"  class="form-control w-75"
                            value={userName}
                            onChange={(e) => addUserName(e)}
                            placeholder="Full Name*"/>
                          </div>
                        <div class="mb-3">
                            <label>Full Address</label>
                            <input type="text" class="form-control w-75"
                            value={userAddress}
                            onChange={(e) => addUserAddress(e)}
                            placeholder="Address*"/>
                        </div>
                        <div class="mb-3">
                            <label>Mobile Number</label>
                            <input type="text" class="form-control w-75"
                             value={userPhone}
                             onChange={(e) => addUserPhone(e)}
                             placeholder="Mobile Number*"/>
                        </div>
                        <div><span id="Message"></span></div>
                    {/* Condition for button Add/Update */}
                    {(() => {
                    if (detail.length > 0){
                        return(
                            <button type="button" onClick={(e) => updateUserdetails(e)} class="btn btn-login mt-4">Update Details</button>
                        )
                        
                    }
                    else{
                        if (isLoading) {
                            return <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        }
                    
                        return (
                            <button type="button" onClick={(e) => addUserdetails(e)} class="btn btn-login mt-4">Add Details</button>
                        );
                        
                    }

                    })()}
                      </form>

                </div>
                <div class="col-lg-4 details-view">
                    <h4>Update Details for</h4>
                    <h6>Email:</h6>
                    <p>{user.email}</p>
                    {/* Condition for view of user detail */}
                    {(() => {
                    if (detail.length > 0){
                        return(
                        <div>
                        <p>Full Name: {detail[0].full_name}</p>
                        <p>Address: {detail[0].address}</p>
                        <p>Mobile Number: {detail[0].contact_no}</p>
                        <a href="/checkout" class="checkout-arrow">Proceed to checkout &#8680;</a>
                        </div>

                        )
                        
                    }
                    else{
                        if (isLoading) {
                            return <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        }
                    
                        return (
                            <p class="update-detail">Please Add User Shipping Details! To complete checkout process.</p>
                        );
                        
                    }

                    })()}
                    
                </div>

            </div>
            
            
        </section>
        );
      }

      else{
        return(
            <section>
            <div class="container-sm d-flex flex-column flex-lg-row justify-content-center align-self-stretc p-5">
                <div class="col-lg-10 login-shadow regcom">
                <p>Please login to continue, click <a href="/">here</a>  to login</p>
                </div>
            </div>
        </section>
      
          );
      }

};

export default Shippingdetails;