import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';

const Checkout = () => {
    const [cart, setCart] = useState([]);
    const [detail, setDetail] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const updateUser = useNavigate;
    const [token, setToken] = useState(null);
    const [cartQty, setCartqty] = useState();

    useEffect(() => {
        // Check if token exists in localStorage (or wherever you store it)
        const storedToken = localStorage.getItem('accessToken');
        if (storedToken) {
          setToken(storedToken);
        }
      }, []);

        useEffect(() => {

        // Creating a timeout within the useEffect hook
        setTimeout(() => {
            setIsLoading(false);
        }, 5000);
        }, []);
            // Fetching User Detail
            useEffect(() => {
                const storedToken = localStorage.getItem('accessToken');
                const fetchData = async () => {
                    const url = 'https://bhenapi.wd99p.com/api/v1/userdetail';
                    try {
                        const response = await fetch(url, {
                            headers: {
                                'Authorization': `Bearer ${storedToken}`,
                            
                            }
                        });
                    
                        if (!response.ok) {
                            
                        }
                    
                        const userData = await response.json();
                        if (userData.length === 0){
                            window.location.replace('/user-details');
                        }
                        setDetail(userData);
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                };
                fetchData();
    }, []);

// fetching cart datas
    useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    const fetchData = async () => {
        const url = 'https://bhenapi.wd99p.com/api/v1/cart/';

        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${storedToken}`,

                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const jsonData = await response.json();
            setCartqty(jsonData.length);
            setCart(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    fetchData();


    }, []);

     // computing carts elements (cart qty, cart total price)
     let sum = 0;
     for (let x = 0; x < cart.length; x++) {
     sum += parseInt(cart[x].qty) * parseInt(cart[x].product.product_price);}
    
     function checkout(e, name, address, contact) {
        e.preventDefault();

        async function saveOrder() {
            const storedToken = localStorage.getItem('accessToken');
            let x = 0;
            while (x < cartQty) {
            let proId = cart[x].product.id;
            let qty = cart[x].qty;
            let price = (parseInt(cart[x].product.product_price)*parseInt(cart[x].qty));
            let cartId = cart[x].id;

            const response = await fetch(
              "https://bhenapi.wd99p.com/api/v1/orders",
              {
                method: 'POST',
                headers: {
                  "Content-Type" : "application/json",
                  "accept" : "application/json",
                  'Authorization': `Bearer ${storedToken}`
                },
                   body: JSON.stringify({
                    product_id: proId,
                    order_qty : qty,
                    order_total_price : price,
                    full_name : name,
                    address: address,
                    contact_no: contact
                   })
              }
            );
            if (response.status !== 200) {
            console.log("error");
            }
            else{
                async function updateCart() {
                    const response = await fetch(
                      "https://bhenapi.wd99p.com/api/v1/cart/"+cartId,
                      {
                        method: 'DELETE',
                        headers: {
                          "Content-Type" : "application/json",
                          "accept" : "application/json",
                          'Authorization': `Bearer ${storedToken}`
                        },
                      }
                    );
                    if (response.status !== 200) {
                      console.log("error");
                      }
                      else{
                        console.log("deleted successfully");
                      }
                  }
                  updateCart();
                x++;
            }
          }
        alert("Thank you for your order \n You will receive message notification on your mobile number within 24 hrs");
        window.location.reload();
        }
        saveOrder();

     }


if(token != null && cartQty != 0){
    return (
    <section>
        <div class="container-sm d-flex flex-column flex-lg-row justify-content-around gap-class align-self-stretc p-3">
        <div class="col-lg-8 text-center cart-big">

            <h1 class="mt-5">Your Cart</h1>
                    <div class="table-responsive">
                    <table class="table mt-4 table-checkout"><thead><tr><th>Product</th><th>Name</th><th>Amount</th><th>Quantity</th><th>Price</th></tr></thead><tbody>
                    {cart.map((carts) => (
                      <tr>
                        <td><img class="rounded-circle cart-photo"  src={carts.product.product_photo}/></td>
                        <td>{carts.product.product_name}</td>
                        <td>₱ {carts.product.product_price}</td>
                        <td>{carts.qty}</td>
                        <td>₱ {(parseInt(carts.product.product_price)*parseInt(carts.qty))}</td>
                      </tr>
                      ))}
                      <tr class="cart-table">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><h6>Total</h6></td>
                        <td>₱ {sum}</td>
                      </tr>
                      </tbody>

                      </table> 
                      </div>
                      
                      {(() => {
                      if (isLoading) {
                        return <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                        }
                    else{
                        return (
                            <div>               
                            <p>Full Name: {detail[0].full_name}</p>
                            <p>Address: {detail[0].address}</p>
                            <p>Mobile Number: {detail[0].contact_no}</p>
                            <button type="button" class="btn btn-dark" onClick={(e) => checkout(e, detail[0].full_name,detail[0].address,detail[0].contact_no)}>Order Now</button> 
                            </div>
                        );  
                    }
                     })()}
                         

        </div>
        </div>
      </section>
    );
}

else{
    if (isLoading) {
        return (
            <section>
            <div class="container-sm d-flex flex-column flex-lg-row justify-content-center align-self-stretc p-5">
                <div class="col-lg-10 login-shadow regcom">
                <p>Your cart is empty. Please wait <b>5</b> seconds to redirect Home Page or <a href="/login">Click here</a>  browse more product.</p>
                </div>
            </div>
        </section>
      )
    }
  
    return (
      window.location.replace('/')
    );
}
}

  
  export default Checkout;
  