const Footer = () => {
  return (
    <footer class="footdesign container-fluid">
    <div class="container-lg border-break">
      <div class="row newsletter">
          <div class="col-lg-4">
            <h3>Subscribe to Newsletter</h3>
            <p>Subscribe newsletter to get 5% on all products.</p>
          </div>
          <div class="col-lg-5">
            <form class="d-flex">
              <input type="text" class="newstext"  placeholder="Enter your Email"/>
              <button type="button" class="btn btn-news">Subscribe</button>
            </form>
          </div>
      </div>
    </div>
    <div class="container-sm d-flex  flex-column flex-lg-row justify-content-end align-self-stretc p-4 border-break">
      <div class="col-lg-4">
        <h4>CONNECT WITH US</h4>
          <ul class="ulfooter">
              <li class="nav-item"><a class="nav-link" aria-current="page" href="https://twitter.com/"><i class="fa-brands fa-twitter social-color"></i> Follow us on twitter </a></li>
              <li class="nav-item"><a class="nav-link" aria-current="page" href="https://pinterest.com/"><i class="fa-brands fa-pinterest social-color"></i> Pin us on Pinterest</a></li>
              <li class="nav-item"><a class="nav-link" aria-current="page" href="https://facebook.com/"><i class="fa-brands fa-facebook social-color"></i> Like us on Facebook</a></li>
              <li class="nav-item"><a class="nav-link" aria-current="page" href="https://instagram.com/"><i class="fa-brands fa-instagram social-color"></i> Follow us on Instagram</a></li>
          </ul>
      </div>
      <div class="col-lg-4">
            <h4>SHOP</h4>
              <ul class="ulfooter">
                  <li class="nav-item"><a class="nav-link" aria-current="page" href="#">Girl Clothing</a></li>
                  <li class="nav-item"><a class="nav-link" aria-current="page" href="#">Boy Clothing</a></li>      
              </ul>
      </div>  
      <div class="col-lg-4">
            <h4>CUSTOMER SERVICE</h4>
              <ul class="ulfooter">
                  <li class="nav-item"><a class="nav-link" aria-current="page" href="#">Contact Us</a></li>   
              </ul>
      </div>     
    </div>

    <div class="copy-right">
      <p>Copyright ©2024 All rights reserved | This template is made by Bhen</p>
    </div>

</footer>
  );
};

export default Footer;
