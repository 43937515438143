import logo from "../img/logo.png";
import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';


const Header = () => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState([]);
  const [cart, setCart] = useState([]);
  const logOut = useNavigate();


// fetching token from local storage if null | !null
  useEffect(() => {
    // Check if token exists in localStorage (or wherever you store it)
    const storedToken = localStorage.getItem('accessToken');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  // fetching cart datas
  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    const fetchData = async () => {
        const url = 'https://bhenapi.wd99p.com/api/v1/cart/';

        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${storedToken}`,

                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const jsonData = await response.json();
            setCart(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    fetchData();


}, []);

// computing carts elements (cart qty, cart total price)
let sum = 0;
let cartElement = cart.length;
for (let x = 0; x < cart.length; x++) {
  sum += parseInt(cart[x].qty) * parseInt(cart[x].product.product_price);}


// Fetching User Data
  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    const fetchData = async () => {
        const url = 'https://bhenapi.wd99p.com/api/v1/user';

        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${storedToken}`,

                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const jsonData = await response.json();
            setUser(jsonData.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    fetchData();
}, []);

// Logout Function
  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('accessToken');
    setToken(null);
    logOut('/');
  };
  
// return if token isnt empty
  if(token != null){
  return (
    <header>
    <div class="container-fluid top-bar">
      <div class="container-lg">
        <div>
          <h6 class="p-2">Welcome <a class ="head-email" data-bs-toggle="tooltip" data-bs-placement="top" title="View User Data" href="/user-details">{user.email}</a></h6><button class="btn btn-sm  logout"onClick={handleLogout}>Log Out</button>
          <button type="button" id="btn-show-favorites" class="btn position-relative cart-button" data-bs-toggle="modal" data-bs-target="#exampleModal">
          <i class="fas fa-shopping-cart cart-size"></i>
            <span id="spn-badge" class="position-absolute mt-2 top-0 start-100 translate-middle badge rounded-pill badge-cart">
            {cartElement}
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="container-fluid navigation-bar">
      <div class="container-fluid">
        <nav class="navbar navbar-expand-xl">
          <div class="container-lg">
            <a class="navbar-brand web-logo" href="/"
              ><img src={logo}
            /></a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a class="nav-link" aria-current="page" href="/"
                    >Home</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" aria-current="page" href="/men-collections"
                    >Men</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" aria-current="page" href="/women-collections"
                    >Women</a>

                </li>
                <li class="nav-item">
                  <a class="nav-link" aria-current="page" href="/baby-collections"
                    >Baby Collection</a>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Pages
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="/shipping-info">Shipping Info</a>
                    </li>
                    <li><a class="dropdown-item" href="/about-us">About Us</a></li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/contact-us">Contact us</a>
                </li>
              </ul>
              {/* <form class="d-flex" role="search">
                <input
                  class="form-control me-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <button class="btn btn-outline-dark" type="submit">
                  Search
                </button>
              </form> */}
            </div>
          </div>
        </nav>
      </div>
    </div>
      {/* Cart Modal */}
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Your Cart</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body table-responsive" id="modal-contents">
              {(() => {
              if (cart.length > 0){
                  return (
                    <table class='table'><thead><tr><th class='modal-th-2'>Product</th><th class='modal-th-3'>Amount</th><th class='modal-th-4'>Quantity</th><th class='modal-th-4'>Price</th></tr></thead><tbody>
                    {cart.map((carts, index) => (
                      <tr>
                        <td><img class="rounded-circle cart-photo"  src={carts.product.product_photo}/></td>
                        <td>{carts.product.product_price}</td>
                        <td>{carts.qty}</td>
                        <td>{(parseInt(carts.product.product_price)*parseInt(carts.qty))}</td>
                      </tr>
                      ))}
                      <tr>
                        <td></td>
                        <td></td>
                        <td>Total</td>
                        <td>{sum}</td>
                      </tr>
                      </tbody>
                      </table>
                  )
              }
              
              else{
                return (
                <div class="cart-box-small">
                <h3>Your Cart is Empty!</h3>
                </div>
            )
              }
            })()}

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-dark" onClick={(e) => {
                      e.preventDefault();
                      window.location.href = "/cart";
                    }}>Open Cart</button>
              </div>
            </div>
          </div>
        </div>
  </header>
  );
}

else{
  return (
  <header>
  <div class="container-fluid top-bar">
    <div class="container-lg">
      <div>
        <button type="button" id="btn-show-favorites" class="btn position-relative" data-bs-toggle="tooltip" data-bs-placement="top" title="Login/Register" onClick={(e) => {e.preventDefault(); window.location.href = "/login";}}>
        <i class="fa-regular fa-user user-size"></i>
        </button>
        <button type="button" id="btn-show-favorites" class="btn position-relative" data-bs-toggle="tooltip"  data-bs-placement="top" title="View Cart" onClick={(e) => {e.preventDefault(); window.location.href = "/login";}}>
        <i class="fas fa-shopping-cart cart-size"></i>
          <span id="spn-badge" class="position-absolute mt-2 top-0 start-100 translate-middle badge rounded-pill badge-cart">
            0
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="container-fluid navigation-bar">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-xl">
        <div class="container-lg">
          <a class="navbar-brand web-logo" href="/"
            ><img src={logo}
          /></a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="/"
                  >Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="/men-collections"
                  >Men</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="/women-collections"
                  >Women</a>

              </li>
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="/baby-collections"
                  >Baby Collection</a>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Pages
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a class="dropdown-item" href="/shipping-info">Shipping Info</a>
                  </li>
                  <li><a class="dropdown-item" href="/about-us">About Us</a></li>
                </ul>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/contact-us">Contact us</a>
              </li>
            </ul>
            {/* <form class="d-flex" role="search">
              <input
                class="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button class="btn btn-outline-dark" type="submit">
                Search
              </button>
            </form> */}
          </div>
        </div>
      </nav>
    </div>
  </div>

</header>
);
}
};

export default Header;
