import { useState, useEffect } from 'react'
import {Link, useNavigate} from 'react-router-dom';
import flashsale from "../img/flash-sale.jpg"

const Regcom = () => {
    setTimeout(function() {
        window.location.replace('/login');
      }, 5000);


    return (
        <section>
        <div class="container-sm d-flex flex-column flex-lg-row justify-content-center align-self-stretc p-5">
            <div class="col-lg-10 login-shadow regcom">
            <p>Successfuly Registered. Please wait <b>5</b> seconds to redirect to Login page or <a href="/login">Click here</a>  to Redirect now</p>
            </div>
        </div>
        
        
    </section>
  );
};

export default Regcom;
