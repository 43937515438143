const Aboutus = () => {
  return (
    <section>
      <div class="container-fluid page-title">
          <h3>
            About Us
          </h3>
      </div>
      <div class="container-sm d-flex flex-column flex-lg-row justify-content-around gap-class align-self-stretc p-5">
        <div class="col-lg-8 text-center">
              <h5>Discover FashionShop by Bhen: Where Style Meets Convenience</h5>

              <p>Welcome to the forefront of fashion at FashionShop by Bhen, your ultimate destination for trend-setting apparel and accessories. Founded with a passion for delivering the latest in fashion directly to your doorstep, we cater to the modern shopper who values both style and convenience.</p>
              <h5>Our Vision</h5>
              <p>At FashionShop by Bhen, we envision a world where fashion is not just a statement but an expression of individuality. We strive to curate collections that resonate with diverse tastes and preferences, ensuring there's something for everyone. Whether you're into timeless classics or cutting-edge trends, our carefully selected range ensures you're always on point.</p>
              <h5>The FashionShop by Bhen Experience </h5>
              <p>Shopping with us is more than just browsing through a catalog; it's an experience crafted to delight. Our intuitive website and mobile app make finding your next favorite piece effortless. Explore our curated sections, from casual chic to evening elegance, and discover curated collections that inspire.</p>
              <h5>Quality and Commitment</h5>
              <p>We understand that fashion is not just about looks but also about quality. That's why we partner with reputable brands and designers known for their craftsmanship and attention to detail. Each item in our inventory is selected with care, ensuring that when you shop with us, you receive not just style but also durability and comfort.</p>
              <h5>Customer-Centric Approach</h5>
              <p>At FashionShop by Bhen, our customers are at the heart of everything we do. We believe in building lasting relationships through exceptional service. Our dedicated support team is always ready to assist you, whether you have a question about sizing, need styling advice, or require assistance with your order.</p>
              <h5>Stay Connected</h5>
              <p>Join our community of fashion enthusiasts and stay updated with the latest trends, style tips, and exclusive offers. Follow us on social media and subscribe to our newsletter to be the first to know about new arrivals and special promotions.</p>
              <h5>Shop FashionShop by Bhen Today</h5>
              <p>Ready to elevate your wardrobe? Explore FashionShop by Bhen today and experience fashion shopping like never before. Whether you're treating yourself or finding the perfect gift, we're here to make every purchase memorable.</p>

              <p>Indulge in style. Embrace convenience. Shop FashionShop by Bhen now and discover the world of limitless fashion possibilities.</p>

              <p>FashionShop by Bhen - Where Your Style Journey Begins.</p>
        </div>
      </div>
    </section>
  );
};

export default Aboutus;
