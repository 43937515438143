import flashsale from "../img/flash-sale.jpg"
import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';

const Signup = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userPass, setUserPass] = useState("");
  const [userPasscon, setUserPasscon] = useState("");
  const regcomplete = useNavigate();
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Check if token exists in localStorage (or wherever you store it)
    const storedToken = localStorage.getItem('accessToken');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);


  function addUserEmail(e) {
    setUserEmail(e.target.value);
  }
  function addUserPass(e) {
    setUserPass(e.target.value);
  }
  function addUserPasscon(e) {
    setUserPasscon(e.target.value);
  }

  function createUser(e) {
    if (userEmail && userPass && userPasscon != null){
    if ( userPass == userPasscon){
    e.preventDefault();
    async function saveUser() {
      const response = await fetch(
        "https://bhenapi.wd99p.com/api/v1/register",
        {
          method: 'POST',
          headers: {
            "Content-Type" : "application/json",
            "accept" : "application/json"},
             body: JSON.stringify({
              email : userEmail,
              password : userPass,
              user_type : "client"
             })
        }

        
      );
      
      if (response.status !== 200) {
          document.getElementById("Message").style.color = "Red";
          document.getElementById("Message").innerHTML = "Email is already taken!"
      } else {
        regcomplete('/regconfirm');
      }


    }

    saveUser();
  
  }

  else{
    document.getElementById("Message").style.color = "Red";
    document.getElementById("Message").innerHTML = "Passwords do NOT match!"

  }
}

else{
    document.getElementById("Message").style.color = "Red";
    document.getElementById("Message").innerHTML = "Please fill out the fields!"
}
  }
if(token != null){
    return(
      <section>
      <div class="container-sm d-flex flex-column flex-lg-row justify-content-center align-self-stretc p-5">
          <div class="col-lg-10 login-shadow regcom">
          <p>Your are already Logged in please go back to home page by clicking <a href="/">here</a>  to Redirect now</p>
          </div>
      </div>
  </section>

    );

  }
  else{
    return (
        <section>
            <div class="container-sm d-flex flex-column flex-lg-row justify-content-center align-self-stretc p-5">
            <div class="col-lg-4 p-3 login-shadow">
                    <h4>Create account</h4>
                    <form>
                          <div class="mb-3">
                            <input  type="email" name="email" id="email"  class="form-control w-75"
                            value={userEmail}
                            onChange={(e) => addUserEmail(e)}
                            placeholder="Email*"/>
                          </div>
                        <div class="mb-3">
                            <input type="password" class="form-control w-75"
                            value={userPass}
                            onChange={(e) => addUserPass(e)}
                            placeholder="Password*"/>
                        </div>
                        <div class="mb-3">
                            <input type="password" class="form-control w-75"
                             value={userPasscon}
                             onChange={(e) => addUserPasscon(e)}
                             placeholder="Re-enter Password*"/>
                        </div>
                        <div><span id="Message"></span></div>
                        <button type="button" class="btn btn-login mt-4"  onClick={(e) => createUser(e)}>Create Account</button>
                      </form>

                      <div class="d-flex flex-column flex-lg-row align-self-stretc">
                        <div class="col-lg-6">
                            <div>Already have an account?</div>
                            <div><a href="/login" class="login-links">SIGN IN &rarr;</a></div>
                        </div>
                      </div>
                </div>
                <div class="col-lg-4 login-shadow">
                    <img src={flashsale} class="w-100 container-image" alt="..."/>
                </div>
            </div>
            
            
        </section>
    );
  }
  };
  
  export default Signup;
  