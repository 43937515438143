import React from "react";
import "./App.css";
import Header from "./components/header";
import Home from "./components/home";
import Footer from "./components/footer";
import Contact from "./components/contact";
import Aboutus from "./components/aboutus";
import Mencol from "./components/menscollection";
import Womencol from "./components/womencol";
import Login from "./components/login";
import Signup from "./components/signup";
import Salebar from "./components/salebar"
import Babycol from "./components/babycollection";
import Allprod from "./components/allproducts";
import Regcom from "./components/regconfirm";
import Product from "./components/products/product";
import Cart from "./components/products/cart";
import Shippingdetails from "./components/usershippingdetails";
import Checkout from "./components/products/checkout";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Shipping from "./components/shippinginfo";
import Customerorders from "./components/products/customerorders";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Salebar/>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contact-us" element={<Contact />} />
          <Route exact path="/about-us" element={<Aboutus />} />
          <Route exact path="/women-collections" element={<Womencol />} />
          <Route exact path="/men-collections" element={<Mencol />} />
          <Route exact path="/baby-collections" element={<Babycol />} />
          <Route exact path="/all-products" element={<Allprod />} />
          <Route exact path="/product" element={<Product />} />
          <Route exact path="/login" element={<Login/>}/>
          <Route exact path="/signup" element={<Signup/>}/>
          <Route exact path="/regconfirm" element={<Regcom/>}/>
          <Route exact path="/cart" element={<Cart/>}/>
          <Route exact path="/user-details" element={<Shippingdetails/>}/>
          <Route exact path="/checkout" element={<Checkout/>}/>
          <Route exact path="/shipping-info" element={<Shipping/>}/>
          <Route exact path="/my-orders" element={<Customerorders/>}/>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
