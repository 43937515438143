import {useLocation} from 'react-router-dom';
import { useEffect, useState } from "react";
import {Link, useNavigate} from 'react-router-dom';

const Product = () => {
  const [product, setProduct] = useState([]);
  const [cart, setCart] = useState([]);
  const cartBlock = useNavigate();
  const location = useLocation();
  const productId = location.state.id;

  useEffect(() => {
    fetch("https://bhenapi.wd99p.com/api/v1/products")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setProduct(data.data);
      });
  }, []); 
console.log(product);
  // fetching cart datas
  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    const fetchData = async () => {
        const url = 'https://bhenapi.wd99p.com/api/v1/cart/';

        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${storedToken}`,

                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const jsonData = await response.json();
            setCart(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    fetchData();
    
      
    }, []);
    
    console.log(cart);

  function addCart(prodId){
    const storedToken = localStorage.getItem('accessToken');

    if (storedToken != null){
    let x = 0;
    let Found = false;
    while (x < cart.length) {
      if (cart[x].product_id == prodId) {
        Found = true;
        break;
      }
      x++;
    }

    if (Found) {
      async function updateCart() {
        const response = await fetch(
          "https://bhenapi.wd99p.com/api/v1/cart/"+cart[x].id,
          {
            method: 'PATCH',
            headers: {
              "Content-Type" : "application/json",
              "accept" : "application/json",
              'Authorization': `Bearer ${storedToken}`
            },
               body: JSON.stringify({    
                qty :  parseInt((cart[x].qty) + 1),
               })
          }
        
          
        );
        if (response.status !== 200) {
          console.log("error");
          }
          else{
            document.location.reload();
          }
      }
      updateCart();
    }
    else{
      async function saveCart() {
        const response = await fetch(
          "https://bhenapi.wd99p.com/api/v1/cart/",
          {
            method: 'POST',
            headers: {
              "Content-Type" : "application/json",
              "accept" : "application/json",
              'Authorization': `Bearer ${storedToken}`
            },
               body: JSON.stringify({
                product_id: prodId,
                qty : 1,
               })
          }
  
          
        );
        if (response.status !== 200) {
        console.log("error");
        }
        else{
          document.location.reload();
        }
      }
      saveCart();
    }
  }
  else{
    cartBlock('/login');
  }
    
  }

  return (
  
    <section class="mt-4 mb-4">
        {product.filter(x => x.id === productId).map((products) => (
      <div class="container-sm d-flex flex-column flex-lg-row justify-content-between gap-class align-self-stretc p-3">
        <div class="col-lg-5">
          <div
            id="carouselExampleAutoplaying"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src={products.product_photo} class="d-block w-100" alt="..." />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="cart-s">
            <h4>{products.product_name}</h4>
            {/* <img class="w-25" src={stars} /> */}
            <h3>₱{products.product_price} <s>₱{products.price_before}</s></h3>
          </div>

          <div class="mt-3">
            <button type="button" class="btn btn-dark btn-lg w-50" onClick={() => addCart(products.id)}>
              Add to Cart
            </button>
          </div>
          <div class="mt-3">
            <h6>Product Details</h6>
            <p>
            {products.product_description}
            </p>
          </div>
        </div>
      </div>
          ))}
    </section>

  );
};

export default Product;
