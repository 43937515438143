import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';

const Dashboard = () => {
    const [orders, setOrders] = useState([]);
    const [token, setToken] = useState(null);

    useEffect(() => {
        // Check if token exists in localStorage (or wherever you store it)
        const storedToken = localStorage.getItem('accessToken');
        if (storedToken) {
          setToken(storedToken);
        }
      }, []);

// fetching cart datas
  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    const fetchData = async () => {
        const url = 'https://bhenapi.wd99p.com/api/v1/orders';
        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${storedToken}`,

                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
                
            }

            const jsonData = await response.json();
            setOrders(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    fetchData();
   

}, []);

console.log(orders);
if(token != null){
return(
<section>


        <div class="container-lg">
        <div class="text-center">
            <h3>Pending Order List</h3>
        </div>
        <div class="table-responsive mt-5">
            <table class="table order-table">
                <thead>
                <tr>
                    <th>Customer Name</th>
                    <th>Customer Address</th>
                    <th>Customer Phone</th>
                    <th>Product Name</th>
                    <th>Qty</th>
                    <th>Total Price</th>
                    <th>Order Time</th>
                    <th>Action</th>
                </tr>
                </thead>
                {orders.map((order) => (
                <tr>
                    <td>{order.full_name}</td>
                    <td>{order.address}</td>
                    <td>{order.contact_no}</td>
                    <td>{order.product.product_name}</td>
                    <td>{order.order_qty}</td>
                    <td>{order.order_total_price}</td>
                    <td>{order.created_at}</td>
                    <td>
                    </td>
                </tr> 
                ))}      
            </table>
        </div>
        </div>


    </section>
)
}
else{
    return(
        <section>
        <div class="container-sm d-flex flex-column flex-lg-row justify-content-center align-self-stretc p-5">
            <div class="col-lg-10 login-shadow regcom">
            <p>Please Log in as an Admin to Access this page <a href="/">Click here</a>  to Login.</p>
            </div>
        </div>
    </section>
  
      );
  
    
}
  };
  
  export default Dashboard;
  