import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';

const Cart = () => {
    const [token, setToken] = useState(null);
    const [cart, setCart] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
      // Check if token exists in localStorage (or wherever you store it)
      const storedToken = localStorage.getItem('accessToken');
      if (storedToken) {
        setToken(storedToken);
      }
    }, []);

    useEffect(() => {

        // Creating a timeout within the useEffect hook
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    }, []);

      // fetching cart datas
  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    const fetchData = async () => {
        const url = 'https://bhenapi.wd99p.com/api/v1/cart/';

        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${storedToken}`,

                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const jsonData = await response.json();
            setCart(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    fetchData();


}, []);

    // computing carts elements (cart qty, cart total price)
    let sum = 0;
    for (let x = 0; x < cart.length; x++) {
    sum += parseInt(cart[x].qty) * parseInt(cart[x].product.product_price);}
      
  
  
      function minus(index) {
        const storedToken = localStorage.getItem('accessToken');
        let Qtys = cart[index].qty;
      
        if (Qtys > 1){
            async function updateCart() {
                const response = await fetch(
                  "https://bhenapi.wd99p.com/api/v1/cart/"+cart[index].id,
                  {
                    method: 'PATCH',
                    headers: {
                      "Content-Type" : "application/json",
                      "accept" : "application/json",
                      'Authorization': `Bearer ${storedToken}`
                    },
                       body: JSON.stringify({    
                        qty :  parseInt((cart[index].qty) - 1),
                       })
                  }
                
                  
                );
                if (response.status !== 200) {
                  console.log("error");
                  }
                  else{
                    document.location.reload();
                  }
              }
              updateCart();
        }
        else {
            async function updateCart() {
                const response = await fetch(
                  "https://bhenapi.wd99p.com/api/v1/cart/"+cart[index].id,
                  {
                    method: 'DELETE',
                    headers: {
                      "Content-Type" : "application/json",
                      "accept" : "application/json",
                      'Authorization': `Bearer ${storedToken}`
                    },
                  }
                );
                if (response.status !== 200) {
                  console.log("error");
                  }
                  else{
                    document.location.reload();
                  }
              }
              updateCart();
        }
      }
      function plus(index) {
        const storedToken = localStorage.getItem('accessToken');
        async function updateCart() {
            const response = await fetch(
              "https://bhenapi.wd99p.com/api/v1/cart/"+cart[index].id,
              {
                method: 'PATCH',
                headers: {
                  "Content-Type" : "application/json",
                  "accept" : "application/json",
                  'Authorization': `Bearer ${storedToken}`
                },
                   body: JSON.stringify({    
                    qty :  parseInt((cart[index].qty) + 1),
                   })
              }
            
              
            );
            if (response.status !== 200) {
              console.log("error");
              }
              else{
                document.location.reload();
              }
          }
          updateCart();
      }

    if(token != null){
    return (
      <section>
        <div class="container-sm d-flex flex-column flex-lg-row justify-content-around gap-class align-self-stretc p-3">
        <div class="col-lg-8 text-center cart-big">
            <h1 class="mt-5">Your Cart</h1>
            {(() => {
              if (cart.length > 0){
                  return (
                    <div>
                    <table class="table mt-4"><thead><tr><th>Product</th><th>Amount</th><th>Quantity</th><th>Price</th></tr></thead><tbody>
                    {cart.map((carts, index) => (
                      <tr>
                        <td><img class="rounded-circle cart-photo"  src={carts.product.product_photo}/></td>
                        <td>₱ {carts.product.product_price}</td>
                        <td><button class="qty-button" onClick={(e) => minus(index)}>-</button>{carts.qty}<button class="qty-button" onClick={(e) => plus(index)}>+</button></td>
                        <td>₱ {(parseInt(carts.product.product_price)*parseInt(carts.qty))}</td>
                      </tr>
                      ))}
                      <tr class="cart-table">
                        <td></td>
                        <td></td>
                        <td><h6>Total</h6></td>
                        <td>₱ {sum}</td>
                      </tr>
                      </tbody>

                      </table> 
                      <button type="button" class="btn btn-dark" onClick={(e) => {
                      e.preventDefault();
                      window.location.href = "/checkout";
                    }}>Proceed to Checkout</button>
                      </div>
                  )
              }
              
              else{
                if (isLoading) {
                    return <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                }
            
                return (
                    <div class="cart-box">
                    <h3>Your Cart is Empty!</h3>
                    </div>
                );
              }
            })()}


        </div>
        </div>
      </section>
    );
  }
  else{
    if (isLoading) {
      return <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  }

  return (
    window.location.replace('/login')
  );
    
  }
  };
  
  export default Cart;
  