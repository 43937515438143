import { useState, useEffect } from 'react'
import {Link, useNavigate} from 'react-router-dom';


const Cards = () => {

  const [product, setProduct] = useState([]);
  const [cart, setCart] = useState([]);
  const cartBlock = useNavigate();

  useEffect(() => {
    fetch("https://bhenapi.wd99p.com/api/v1/products")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setProduct(data.data);
      });
  }, []);
 // fetching cart datas
 useEffect(() => {
  const storedToken = localStorage.getItem('accessToken');
  const fetchData = async () => {
      const url = 'https://bhenapi.wd99p.com/api/v1/cart/';

      try {
          const response = await fetch(url, {
              headers: {
                  'Authorization': `Bearer ${storedToken}`,

              }
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          const jsonData = await response.json();
          setCart(jsonData);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };
  fetchData();
  
    
  }, []);
  
  console.log(cart);

function addCart(prodId){
  const storedToken = localStorage.getItem('accessToken');

  if (storedToken != null){
  let x = 0;
  let Found = false;
  while (x < cart.length) {
    if (cart[x].product_id == prodId) {
      Found = true;
      break;
    }
    x++;
  }

  if (Found) {
    async function updateCart() {
      const response = await fetch(
        "https://bhenapi.wd99p.com/api/v1/cart/"+cart[x].id,
        {
          method: 'PATCH',
          headers: {
            "Content-Type" : "application/json",
            "accept" : "application/json",
            'Authorization': `Bearer ${storedToken}`
          },
             body: JSON.stringify({    
              qty :  parseInt((cart[x].qty) + 1),
             })
        }
      
        
      );
      if (response.status !== 200) {
        console.log("error");
        }
        else{
          document.location.reload();
        }
    }
    updateCart();
  }
  else{
    async function saveCart() {
      const response = await fetch(
        "https://bhenapi.wd99p.com/api/v1/cart/",
        {
          method: 'POST',
          headers: {
            "Content-Type" : "application/json",
            "accept" : "application/json",
            'Authorization': `Bearer ${storedToken}`
          },
             body: JSON.stringify({
              product_id: prodId,
              qty : 1,
             })
        }

        
      );
      if (response.status !== 200) {
      console.log("error");
      }
      else{
        document.location.reload();
      }
    }
    saveCart();
  }
}
else{
  cartBlock('/login');
}
}

const navigate = useNavigate();
async function openProduct(prodId, pName) {
navigate('/product/?'+pName ,{state:{id:prodId}});}

  return (
<section class="mt-5">
  <div class="container-lg">
    <div class="card">
      <div class="card-header card-headcustom">
          <h5 class="card-title">Featured Products</h5>
          <ul class="nav nav-tabs card-header-tabs" data-bs-tabs="tabs">
              <li class="nav-item">
                  <a class="nav-link active" aria-current="true" data-bs-toggle="tab" href="#men">Women</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#women">Men</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#baby">Baby</a>
            </li>
          </ul>
      </div>
      <form class="card-body tab-content">
          <div class="tab-pane active" id="men">
            <div class="row row-cols-1 row-cols-lg-4">

            {product.filter(x => x.product_category === 'Female' && x.featured === 'yes').map((female) => (
              <div class="col">
                  <div class="card collection-container">
                      <img src={female.product_photo} class="card-img-top container-image" alt="..."/>
                      <div class="middle">
                      <div class="cart-view">
                        <button type="button" id="btn-show-favorites" class="btn position-relative cart-btn border" 
                        onClick={() => addCart(female.id)}
                        data-bs-toggle="tooltip" data-bs-placement="top" title="Add to Cart">
                          <i class="fas fa-shopping-cart cart-size"></i>
                          </button>
                          <button type="button" id="btn-show-favorites" class="btn position-relative view-btn border" 
                          onClick={(e) => openProduct(female.id, female.product_name)}
                          data-bs-toggle="tooltip" data-bs-placement="top" title="View Product">
                            <i class="fa fa-eye"></i>
                            </button>
                      </div>
                  </div>
                </div>
                <div class="home-productdetails mt-3">
                  <h6>{female.product_name}
                  </h6>
                  <h6>₱{female.product_price} <s>₱{female.product_price}</s></h6>
                </div>
              </div>
            ))}

              </div>
          </div>
          <div class="tab-pane" id="women">
            <div class="row row-cols-1 row-cols-lg-4">
            {product.filter(x => x.product_category === 'Men' && x.featured === 'yes').map((male) => (
              <div class="col">
                <div class="card collection-container">
                    <img src={male.product_photo} class="card-img-top container-image" alt="..."/>
                    <div class="middle">
                      <div class="cart-view">
                        <button type="button" id="btn-show-favorites" class="btn position-relative cart-btn border" 
                        onClick={() => addCart(male.id)}
                        data-bs-toggle="tooltip" data-bs-placement="top" title="Add to Cart">
                          <i class="fas fa-shopping-cart cart-size"></i>
                          </button>
                          <button type="button" id="btn-show-favorites" class="btn position-relative view-btn border" 
                          onClick={(e) => openProduct(male.id, male.product_name)}
                          data-bs-toggle="tooltip" data-bs-placement="top" title="View Product">
                            <i class="fa fa-eye"></i>
                            </button>
                      </div>
                  </div>
                </div>
                <div class="home-productdetails mt-3">
                  <h6>{male.product_name}
                  </h6>
                  <h6>₱{male.product_price} <s>₱{male.price_before}</s></h6>
                </div>
            </div>
           ))}

              </div>
          </div>
          <div class="tab-pane" id="baby">
            <div class="row row-cols-1 row-cols-lg-4">
            {product.filter(x => x.product_category === 'Baby' && x.featured === 'Yes').map((baby) => (
              <div class="col">
                  <div class="card collection-container">
                      <img src={baby.product_photo} class="card-img-top container-image" alt="..."/>
                      <div class="middle">
                      <div class="cart-view">
                        <button type="button" id="btn-show-favorites" class="btn position-relative cart-btn border" 
                        onClick={() => addCart(baby.id)}
                        data-bs-toggle="tooltip" data-bs-placement="top" title="Add to Cart">
                          <i class="fas fa-shopping-cart cart-size"></i>
                          </button>
                          <button type="button" id="btn-show-favorites" class="btn position-relative view-btn border" 
                          onClick={(e) => openProduct(baby.id, baby.product_name)}
                          data-bs-toggle="tooltip" data-bs-placement="top" title="View Product">
                            <i class="fa fa-eye"></i>
                            </button>
                      </div>
                  </div>
                </div>
                <div class="home-productdetails mt-3">
                  <h6>{baby.product_name}
                  </h6>
                  <h6>₱{baby.product_price} <s>₱{baby.product_price}</s></h6>
                </div>
              </div>
            ))}
              </div>
          </div>
      </form>
 </div>
  </div>
</section>
  );
};

export default Cards;
