import svg1 from "../img/icon/services1.svg"
import svg2 from "../img/icon/services2.svg"
import svg3 from "../img/icon/services3.svg"
import svg4 from "../img/icon/services4.svg"

const Newsletter = () => {
  return (
    <section>
      <div class="badges container-fluid mt-5">
          <div class="container-lg">
            <div class="row row-cols-1 row-cols-lg-4 services-container">
                  <div class="col home-services services-border">
                    <img src={svg1}/>
                    <h5 class="mt-3">Fast & Free Delivery</h5>
                    <p>Free delivery on all orders</p>
                  </div>
                  <div class="col home-services services-border">
                    <img src={svg2}/>
                    <h5 class="mt-3">Secure Payment</h5>
                    <p>Hassle free transactions</p>
                  </div>
                  <div class="col home-services services-border">
                    <img src={svg3}/>
                    <h5 class="mt-3">Money Back Guarantee</h5>
                    <p>Within 30 days after purchase</p>
                  </div>
                  <div class="col home-services">
                    <img src={svg4}/>
                    <h5 class="mt-3">Online Support</h5>
                    <p>24/7 Customer Support Service</p>
                  </div>

            </div>
          </div>
      </div>
    </section>
  );
};

export default Newsletter;
