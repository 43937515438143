import col1 from "../img/mens-col.jpg"
import col2 from "../img/womens-col.jpg"
import col3 from "../img/baby-col.jpg"


const Collections = () => {
  return (
    <section class="container-fluid">
        <div class="container-lg mt-4">
            <div class="row row-cols-1 row-cols-lg-3">
                <div class="col">
                    <div class="card collection-container">
                        <img src={col1} class="card-img-top container-image" alt="..."/>
                        <div class="middle">
                        <div class="text-col">
                          <h4>Men's Fashion</h4>
                          <button type="button" class="btn btn-dark"              
                          onClick={(e) => {
                          e.preventDefault();
                          window.location.href = "/men-collections";
                    }}>Browse Collection</button></div>
                    </div>
                  </div>
                </div>
                <div class="col">
                    <div class="card collection-container">
                        <img src={col2} class="card-img-top container-image" alt="..."/>
                        <div class="middle">
                          <div class="text-col">
                            <h4>Women's Fashion</h4>
                            <button type="button" class="btn btn-dark"              
                             onClick={(e) => {
                              e.preventDefault();
                             window.location.href = "/women-collections";
                             }}>Browse Collection</button></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card collection-container">
                        <img src={col3} class="card-img-top container-image" alt="..."/>
                        <div class="middle">
                          <div class="text-col">
                            <h4>Baby Fashion</h4> 
                            <button type="button" class="btn btn-dark"              
                             onClick={(e) => {
                              e.preventDefault();
                             window.location.href = "/baby-collections";
                             }}>Browse Collection</button></div>
                        </div>
                    </div>
                </div>
                
              </div>
        </div>
    </section>
  );
};

export default Collections;
