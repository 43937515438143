const Shipping = () => {
    return (
      <section>
        <div class="container-fluid page-title">
            <h3>
              Shipping Information & Policies
            </h3>
        </div>
        <div class="container-lg text-center mt-5 mb-5">
        <p>Shipping Information Shipping Locations We currently ship within the Philippines. Unfortunately, we do not offer international shipping at this time.</p>

<p>Shipping Rates Shipping rates are calculated based on the destination and the weight of the package. Rates start at &#8369;X for standard shipping within Metro Manila and &#8369;Y for provincial areas.</p>

<p>Delivery Time Orders are processed within 1-2 business days. Delivery times vary depending on the shipping location:</p>

<p>Metro Manila: 2-3 business days Provincial Areas: 3-5 business days Tracking Orders Once your order has been shipped, you will receive a tracking number via email or SMS. You can track your order through our website.</p>

<p>Shipping Providers We partner with trusted courier services to ensure reliable and prompt delivery of your orders.</p>

<p>Shipping Policies Shipping Address Please ensure that the shipping address provided is accurate and complete. We are not responsible for orders delivered to incorrect addresses provided by the customer.</p>

<p>Delivery Attempts Our courier will make multiple attempts to deliver your package. If delivery is unsuccessful after these attempts, the package will be returned to us. Additional shipping charges may apply for re-delivery.</p>

<p>Order Tracking You can track the status of your order using the tracking number provided in your shipping confirmation email or SMS.</p>

<p>Order Confirmation You will receive an order confirmation email or SMS once your order has been successfully placed. Please review the order details promptly and contact us immediately if there are any discrepancies.</p>

<p>Shipping Delays In rare cases, shipping delays may occur due to unforeseen circumstances such as weather conditions or natural disasters. We will do our best to keep you informed and minimize any inconvenience caused.</p>

<p>Shipping Charges Shipping charges are non-refundable except in cases where we have made a shipping error.</p>

<p>Returns and Exchanges Please refer to our Returns and Exchanges Policy for information on returning or exchanging items purchased from our store.</p>
        </div>

      </section>
    );
  };
  
  export default Shipping;
  