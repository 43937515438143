import flashsale from "../img/flash-sale.jpg"
import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';

const Login = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userPass, setUserPass] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const regcomplete = useNavigate();
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Check if token exists in localStorage (or wherever you store it)
    const storedToken = localStorage.getItem('accessToken');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  function confirmUserEmail(e) {
    setUserEmail(e.target.value);
  }
  function confirmUserPass(e) {
    setUserPass(e.target.value);
  }
  function logUser(e) {
    e.preventDefault();
    async function saveUser() {
      const response = await fetch(
        "https://bhenapi.wd99p.com/api/v1/login",
        {
          method: 'POST',
          headers: {
            "Content-Type" : "application/json",
            "accept" : "application/json"},
             body: JSON.stringify({
              email : userEmail,
              password : userPass,
             })
        }

        
      );
      
      if (response.status !== 200) {
          document.getElementById("Message").style.color = "Red";
          document.getElementById("Message").innerHTML = "Invalid Email/Password"
      } else {
        const data = await response.json();
        // Assuming your Laravel API returns a token in the response
        const { access_token } = data;
        
  
        // Save token to localStorage
        localStorage.setItem('accessToken', access_token);
        regcomplete('/');
        window.location.reload();
      }


    }

    saveUser();
  
  }
  if(token != null){
    return(
      <section>
      <div class="container-sm d-flex flex-column flex-lg-row justify-content-center align-self-stretc p-5">
          <div class="col-lg-10 login-shadow regcom">
          <p>Your are already Logged in please go back to home page by clicking <a href="/">here</a>  to Redirect now</p>
          </div>
      </div>
  </section>

    );

  }

  else{
    return (
        <section>
            <div class="container-sm d-flex flex-column flex-lg-row justify-content-center align-self-stretc p-5">
                <div class="col-lg-4 p-3 login-shadow">
                    <h4>Log In</h4>
                    <form>
                        <div class="mb-3">
                          <label for="recipient-name" class="col-form-label">Username or Email:</label>
                          <input type="text" class="form-control w-75"
                            value={userEmail}
                            onChange={(e) => confirmUserEmail(e)}
                          id="recipient-name" placeholder="Username or Email"/>
                        </div>
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Password</label>
                            <input type="password" class="form-control w-75" 
                              value={userPass}
                              onChange={(e) => confirmUserPass(e)}
                            id="recipient-name" placeholder="Password"/>
                          <span id="Message"></span><br/>
                          <button type="button" class="btn btn-login mt-4"   onClick={(e) => logUser(e)}>Login</button>
                        </div>
                      </form>
                      <div class="d-flex flex-column flex-lg-row align-self-stretc">
                        <div class="col-lg-6">
                            <div>Don't have and account?</div>
                            <div><a href="/signup" class="login-links">SIGN UP &rarr;</a></div>
                        </div>

                      </div>
                </div>
                <div class="col-lg-4 login-shadow">
                    <img src={flashsale} class="w-100 container-image" alt="..."/>
                </div>
            </div>
            
            
        </section>
    );
  }
  };
  
  export default Login;
  