import { useState, useEffect } from 'react'
import {Link, useNavigate} from 'react-router-dom';

const Womencol = () => {
  const [product, setProduct] = useState([]);
  const [cart, setCart] = useState([]);
  const cartBlock = useNavigate();

  useEffect(() => {
    fetch("https://bhenapi.wd99p.com/api/v1/products")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setProduct(data.data);
      });
  }, []); 
 // fetching cart datas
 useEffect(() => {
  const storedToken = localStorage.getItem('accessToken');
  const fetchData = async () => {
      const url = 'https://bhenapi.wd99p.com/api/v1/cart/';

      try {
          const response = await fetch(url, {
              headers: {
                  'Authorization': `Bearer ${storedToken}`,

              }
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          const jsonData = await response.json();
          setCart(jsonData);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };
  fetchData();
  
    
  }, []);
  
  console.log(cart);

function addCart(prodId){
  const storedToken = localStorage.getItem('accessToken');

  if (storedToken != null){
  let x = 0;
  let Found = false;
  while (x < cart.length) {
    if (cart[x].product_id == prodId) {
      Found = true;
      break;
    }
    x++;
  }

  if (Found) {
    async function updateCart() {
      const response = await fetch(
        "https://bhenapi.wd99p.com/v1/cart/"+cart[x].id,
        {
          method: 'PATCH',
          headers: {
            "Content-Type" : "application/json",
            "accept" : "application/json",
            'Authorization': `Bearer ${storedToken}`
          },
             body: JSON.stringify({    
              qty :  parseInt((cart[x].qty) + 1),
             })
        }
      
        
      );
      if (response.status !== 200) {
        console.log("error");
        }
        else{
          document.location.reload();
        }
    }
    updateCart();
  }
  else{
    async function saveCart() {
      const response = await fetch(
        "https://bhenapi.wd99p.com/api/v1/cart/",
        {
          method: 'POST',
          headers: {
            "Content-Type" : "application/json",
            "accept" : "application/json",
            'Authorization': `Bearer ${storedToken}`
          },
             body: JSON.stringify({
              product_id: prodId,
              qty : 1,
             })
        }

        
      );
      if (response.status !== 200) {
      console.log("error");
      }
      else{
        document.location.reload();
      }
    }
    saveCart();
  }
}
else{
  cartBlock('/login');
}
}

const navigate = useNavigate();
async function openProduct(prodId, pName) {
navigate('/product/?'+pName ,{state:{id:prodId}});}
  return (
    <section class="mb-5">
      <div class="container-fluid page-title">
          <h3>
            Women's Collections
          </h3>
      </div>
      <div class="container-lg mt-4">
        <div class="row row-cols-1 row-cols-md-4">
      
        {product.filter(x => x.product_category === 'Female').map((female) => (
              <div class="col">
                  <div class="card collection-container">
                      <img src={female.product_photo} class="card-img-top container-image" alt="..."/>
                      <div class="middle">
                      <div class="cart-view">
                        <button type="button" id="btn-show-favorites" class="btn position-relative cart-btn border" 
                         onClick={() => addCart(female.id)}
                        data-bs-toggle="tooltip" data-bs-placement="top" title="Add to Cart">
                          <i class="fas fa-shopping-cart cart-size"></i>
                          </button>
                          <button type="button" id="btn-show-favorites" class="btn position-relative view-btn border" 
                          onClick={(e) => openProduct(female.id, female.product_name)}
                          data-bs-toggle="tooltip" data-bs-placement="top" title="View Product">
                            <i class="fa fa-eye"></i>
                            </button>
                      </div>
                  </div>
                </div>
                <div class="home-productdetails mt-3">
                  <h6>{female.product_name}
                  </h6>
                  <h6>₱{female.product_price} <s>₱{female.product_price}</s></h6>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Womencol;
